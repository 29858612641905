import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g filter=\"url(#filter0_b_9401_2119)\"><rect width=\"24\" height=\"24\" rx=\"12\" fill=\"currentColor\"></rect><path d=\"M18.4956 12.2499C18.4956 12.4487 18.4386 12.6393 18.3371 12.7798C18.2356 12.9204 18.0979 12.9993 17.9544 12.9993H6.0471C5.90355 12.9993 5.76589 12.9204 5.66439 12.7798C5.56288 12.6393 5.50586 12.4487 5.50586 12.2499C5.50586 12.0511 5.56288 11.8605 5.66439 11.72C5.76589 11.5794 5.90355 11.5005 6.0471 11.5005H17.9544C18.0979 11.5005 18.2356 11.5794 18.3371 11.72C18.4386 11.8605 18.4956 12.0511 18.4956 12.2499Z\" fill=\"white\"></path></g><defs><filter id=\"filter0_b_9401_2119\" x=\"-3.28528\" y=\"-3.28528\" width=\"30.5706\" height=\"30.5706\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"></feFlood><feGaussianBlur in=\"BackgroundImageFix\" stdDeviation=\"1.64264\"></feGaussianBlur><feComposite in2=\"SourceAlpha\" operator=\"in\" result=\"effect1_backgroundBlur_9401_2119\"></feComposite><feBlend mode=\"normal\" in=\"SourceGraphic\" in2=\"effect1_backgroundBlur_9401_2119\" result=\"shape\"></feBlend></filter></defs>", 2)
  ])))
}
export default { render: render }