<template>
  <slot :scroll="scroll"/>

  <div v-show="show" class="scroll-indicator">
    <div v-for="(_, n) in count"
         class="dot"
         :class="{ active: n === visibleIndex }"
    ></div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useDebounceFn } from "@vueuse/core";
import { getHorizontalVisibilityPercentage } from "@/utils/htmlUtils.js";
import { findIndexOfMax } from "@/utils/numberUtils.js";

const props = defineProps({
  show: Boolean,
  count: Number,
  parent: String,
  child: String,
});
const visibleIndex = ref(0);

const scroll = useDebounceFn(() => {
  const parentEl = document.querySelector(props.parent);
  const childList = [...parentEl.querySelectorAll(props.child)];
  const visibilityList = childList.map(el => getHorizontalVisibilityPercentage(el, parentEl));
  visibleIndex.value = findIndexOfMax(visibilityList);
}, 100);
</script>

<style lang="scss" scoped>
.scroll-indicator {
  display: flex;
  gap: 8px;

  .dot {
    width: 6px;
    aspect-ratio: 1/1;
    background-color: $grey-50;
    border-radius: 50%;

    &.active {
      background-color: $brand-primary;
    }
  }
}
</style>