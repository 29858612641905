import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "24",
      height: "24",
      rx: "12",
      fill: "currentColor"
    }, null, -1),
    _createElementVNode("path", {
      d: "M16.8716 12.0003C16.8716 12.1079 16.8289 12.2112 16.7527 12.2873C16.6766 12.3634 16.5733 12.4062 16.4657 12.4062H12.4062V16.4657C12.4062 16.5733 12.3634 16.6766 12.2873 16.7527C12.2112 16.8289 12.1079 16.8716 12.0003 16.8716C11.8926 16.8716 11.7894 16.8289 11.7132 16.7527C11.6371 16.6766 11.5943 16.5733 11.5943 16.4657V12.4062H7.53485C7.42719 12.4062 7.32394 12.3634 7.24781 12.2873C7.17168 12.2112 7.12891 12.1079 7.12891 12.0003C7.12891 11.8926 7.17168 11.7894 7.24781 11.7132C7.32394 11.6371 7.42719 11.5943 7.53485 11.5943H11.5943V7.53485C11.5943 7.42719 11.6371 7.32394 11.7132 7.24781C11.7894 7.17168 11.8926 7.12891 12.0003 7.12891C12.1079 7.12891 12.2112 7.17168 12.2873 7.24781C12.3634 7.32394 12.4062 7.42719 12.4062 7.53485V11.5943H16.4657C16.5733 11.5943 16.6766 11.6371 16.7527 11.7132C16.8289 11.7894 16.8716 11.8926 16.8716 12.0003Z",
      fill: "white"
    }, null, -1)
  ])))
}
export default { render: render }